import React from "react"
import {
  makeStyles,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  CircularProgress,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

import { graphql, useStaticQuery } from "gatsby";

import { logger } from "../config/pino"
import { sendEmail } from "../services/backend"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
  },
  cardText: {
    textAlign: "center",
  },
  media: {
    maxWidth: "500px",
    objectFit: "contain",
  },
  cardRow: {
    display: "flex",
    "flex-direction": "row",
  },
  cardInputRow: {
    "border-bottom": "1px solid black",
    "margin-bottom": "1.2rem",
  },
  header: {
    textAlign: "center",
    margin: "auto",
    "margin-left": "0%",
    "margin-bottom": "1.5rem",
  },
  subheader: {
    margin: "auto",
    "margin-left": "0%",
    fontSize: "0.9rem",
  },
  contactForm: {
    width: "100%",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    "font-weight": "400",
    "line-height": "1.334",
    "letter-spacing": "0em",
  },
  contactFormField: {
    width: "100%",
    height: "2rem",
    border: "none",
    outline: "none",
    position: "relative",
  },
  contactFormTextArea: {
    resize: "vertical",
    "min-height": "150px",
    width: "100%",
    height: "1.4rem",
    border: "none",
    outline: "none",
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: "#000000",
  },
}))

export function ContactUs() {
  const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    backendURL
                }
            }
        }
    `)

  const backend = !!site.siteMetadata ? site.siteMetadata.backendURL : "http://localhost:3000"

  const [radioValue, setRadioValue] = React.useState("other")
  const [nameSender, setNameSender] = React.useState("")
  const [emailSender, setEmailSender] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [sendState, setSendState] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [sendError, setSendError] = React.useState(false);
  const [errors, setErrors] = React.useState({name: null, email: null, text: null});

  function submitEmail(e) {
    e.preventDefault()
    const emailData = {
      from: emailSender,
      subject: radioValue,
      name: nameSender,
      text: description,
    }

    setIsLoading(true)

    sendEmail(emailData, backend)
      .then(async response => {
        setIsLoading(false)

        if (response.ok) {
          setSendState(true)
        } else {
          setSendError(true)
          const body = await response.json()
          if (!!body.errors) {
            setErrors(body.errors)
          }
        }
      })
      .catch(err => {
        //Parse and set errors
        logger.error(
          `Recieved the following error when attempting to send email: ${err}`
        )
        setSendError(true)
      })
  }

  const handleRadioChange = event => {
    setRadioValue(event.target.value)
  }
  const handleNameChange = event => {
    setNameSender(event.target.value)
  }
  const handleEmailChange = event => {
    setEmailSender(event.target.value)
  }
  const handleDescChange = event => {
    setDescription(event.target.value)
  }

  const classes = useStyles()

  return (
    <Card variant="outlined" className={classes.card}>
      <CardMedia
        className={classes.media}
        component="img"
        image={"/SocialPreviewKS.png"}
        title="Kyne Software NFT"
      />

      {sendState == false && (
        <CardContent className={classes.cardContent}>
          <div className={classes.cardRow}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.header}
              gutterBottom
            >
              Contact Us
            </Typography>
          </div>

          {/* the actual form div */}
          <div className={classes.cardRow}>
            <div style={{ width: "100%" }}>
              {sendError && (
                <Alert severity="error">
                  Your form has encountered a problem.
                </Alert>
              )}
              <form
                onSubmit={e => submitEmail(e)}
                className={classes.contactForm}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="radioInput"
                    name="radioInput"
                    value={radioValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="purchaseNft"
                      control={<Radio />}
                      label={
                        <Typography style={{ fontSize: "0.8rem" }}>
                          I want to purchase an NFT
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="listValidator"
                      control={<Radio />}
                      label={
                        <Typography style={{ fontSize: "0.8rem" }}>
                          I want to list a validator as an NFT
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label={
                        <Typography style={{ fontSize: "0.8rem" }}>
                          Other
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <div className={classes.cardInputRow}>
                  {!!errors.name && (
                    <Alert severity="error">{errors.name}</Alert>
                  )}
                  <div style={{ width: "100%", marginTop: "1rem" }}>
                    <input
                      type="text"
                      id="name"
                      value={nameSender}
                      onChange={handleNameChange}
                      name="name"
                      className={classes.contactFormField}
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div className={classes.cardInputRow}>
                  {!!errors.email && (
                    <Alert severity="error">{errors.email}</Alert>
                  )}
                  <div style={{ width: "100%" }}>
                    <input
                      type="email"
                      id="email"
                      value={emailSender}
                      onChange={handleEmailChange}
                      name="email"
                      className={classes.contactFormField}
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div className={classes.cardInputRow}>
                  {!!errors.text && (
                    <Alert severity="error">{errors.text}</Alert>
                  )}
                  <div style={{ width: "100%" }}>
                    <textarea
                      id="email"
                      name="message"
                      value={description}
                      onChange={handleDescChange}
                      className={classes.contactFormTextArea}
                      placeholder="Message"
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <div>Submit</div>
                  )}
                </Button>
              </form>
            </div>
          </div>
        </CardContent>
      )}

      {/* display a thank you card on send - wasn't sure what to do with this */}
      {sendState == true && (
        <CardContent className={classes.cardContent}>
          <div className={classes.cardRow}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.header}
              gutterBottom
            >
              Thank You! Your query is with the team.
            </Typography>
          </div>
        </CardContent>
      )}
    </Card>
  )
}
