import React from "react"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContactUs } from "../components/ContactUs"

export default function Contact() {
  return (
    <Layout>
      <SEO title="Kyne Software NFT" />
      <Container maxWidth="xs">
        <ContactUs/>
      </Container>
    </Layout>
  )
}

